import { render, staticRenderFns } from "./Security.vue?vue&type=template&id=56d74286&scoped=true"
import script from "./Security.vue?vue&type=script&lang=js"
export * from "./Security.vue?vue&type=script&lang=js"
import style0 from "./Security.vue?vue&type=style&index=0&id=56d74286&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d74286",
  null
  
)

export default component.exports