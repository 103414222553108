<template>
  <div class="change-pwd-container">
    <div class="change-pwd-form">
      <div class="form-title" style="margin-bottom: 20px">设置支付密码</div>
      <div class="form-field">
        <el-input
            placeholder="请输入原支付密码"
            type="password"
            v-model="oldPwd"
            :validate-event="true"
            @input="handleInput('oldPwd')"
            show-password
        ></el-input>
      </div>
      <div class="form-field">
        <el-input
            placeholder="请输入新支付密码"
            type="password"
            v-model="newPwd"
            :validate-event="true"
            @input="handleInput('newPwd')"
            show-password
        ></el-input>
      </div>
      <div class="form-field">
        <el-input
            placeholder="请再次确认新支付密码"
            type="password"
            v-model="newPwdAgain"
            :validate-event="true"
            @input="handleInput('newPwdAgain')"
            show-password
        ></el-input>
      </div>
      <div class="form-actions">
        <div class="btn" style="border: 1px #CCCCCC" @click="cancel">取消</div>
        <div class="btn" @click="step">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "changePwd",
  data() {
    return {
      oldPwd: "",
      newPwd: "",
      newPwdAgain: "",
    };
  },
  methods: {
    handleInput(field) {
      this[field] = this[field].slice(0, 6);
    },
    cancel() {
      this.$emit("change", false);
    },
    step() {
      if (this.oldPwd === "") {
        this.utils.err("请输入原支付密码");
        return;
      }
      if (this.newPwd === "") {
        this.utils.err("请输入新支付密码");
        return;
      }
      if (this.newPwd.length < 6) {
        this.utils.err("密码至少为6位字符");
        return;
      }
      if (this.newPwdAgain === "") {
        this.utils.err("请再次确认新支付密码");
        return;
      }
      if (this.newPwd !== this.newPwdAgain) {
        this.utils.err("密码不一致");
        return;
      }
      const params = {
        oldPwd: this.oldPwd,
        newPwd: this.newPwd,
      };
      console.log(params)
      this.newApi.saveBgbPayPwd(params).then((res) => {
        if(res.isSuccess == 1){
          this.oldPwd = "";
          this.newPwd = "";
          this.newPwdAgain = "";
          this.utils.sus("修改成功");
          this.$emit("change", false);
        }
      });
    },
  },
}
</script>

<style scoped>
.change-pwd-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-pwd-form {
  width: 100%;
  padding: 20px 30px;
}

.change-pwd-container .btn {
    width: 200px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    padding: 4px 0;
}

.form-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 15px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}
</style>
